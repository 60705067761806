<template>
  <a-rate
    v-bind="record.options"
    :value="Number(value)"
    @change="change"
    @blur="blur"
    @focus="focus"
    @hoverChange="hoverChange"
    @keydown="keydown"
  />
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxRate',
  mixins: [formEleMixin],
  methods: {
    /**
     * 选择时的回调
     * @param value
     */
    change (value) {
      this.$emit('change', value, this.record.model)
    },
    /**
     * 失去焦点时的回调
     */
    blur () {
      this.$emit('blur', this.record.model)
    },
    /**
     * 获取焦点时的回调
     */
    focus () {
      this.$emit('focus', this.record.model)
    },
    /**
     * 鼠标经过时数值变化的回调
     * @param value
     */
    hoverChange (value) {
      this.$emit('hoverChange', value, this.record.model)
    },
    /**
     * 按键回调
     * @param e
     */
    keydown (e) {
      this.$emit('keydown', e, this.record.model)
    }
  }
}
</script>
